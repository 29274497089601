<template>
  <v-dialog :value="value" persistent width="800">
    <v-card>
      <v-toolbar dark flat color="#1C3144">
        <v-btn icon @click="$emit('update:value', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title
          >{{
            isExistingRecap ? 'Edit Recap Addition' : 'Create Recap Addition'
          }}
        </v-toolbar-title>
      </v-toolbar>
      <v-container class="ma-0 pa-6">
        <v-form ref="form">
          <v-row dense>
            <v-col>
              <v-autocomplete
                auto-select-first
                outlined
                dense
                background-color="#fff"
                label="Recap Scope"
                :items="scopes"
                :rules="[(v) => !!v || 'Recap Scope is Required']"
                item-text="description"
                item-value="id"
                v-model="recap.scope"
                @change="delete recap.ad_group, delete recap.store"
              />
            </v-col>
            <v-col>
              <v-autocomplete
                auto-select-first
                outlined
                dense
                background-color="#fff"
                label="Recap Component"
                :items="components"
                item-text="description"
                item-value="id"
                return-object
                v-model="recap.component"
                :menu-props="{ maxHeight: 135 }"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                outlined
                dense
                background-color="#fff"
                :min="recap.amount < 0 || recap.amount > 0"
                label="Amount"
                prefix="$"
                placeholder="0.00"
                :rules="recapAmountRules"
                v-model="recap.amount"
                @change="formatAmount($event)"
                autocomplete="off"
              />
            </v-col>
            <v-col>
              <v-autocomplete
                auto-select-first
                outlined
                dense
                background-color="#fff"
                label="Period"
                :items="periods"
                item-text="description"
                item-value="id"
                v-model="recap.period"
                :menu-props="{ maxHeight: 135 }"
              />
            </v-col>
            <v-col>
              <v-autocomplete
                auto-select-first
                outlined
                dense
                background-color="#fff"
                label="Year"
                :items="years"
                item-text="description"
                item-value="id"
                v-model="recap.year"
                :menu-props="{ maxHeight: 135 }"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="7">
              <v-autocomplete
                :disabled="this.recap.scope === 'Ad Group'"
                auto-select-first
                outlined
                dense
                background-color="#fff"
                label="Store"
                :items="stores"
                :rules="storeRules"
                item-text="name"
                item-value="id"
                v-model="recap.store"
                return-object
                :menu-props="{ maxHeight: 135 }"
              />
            </v-col>
            <v-col>
              <v-autocomplete
                required
                :disabled="this.recap.scope === 'Store'"
                :items="adGroups"
                label="Ad Group"
                :menu-props="{ maxHeight: 135 }"
                auto-select-first
                :rules="adGroupRules"
                background-color="#fff"
                dense
                item-text="description"
                item-value="id"
                outlined
                return-object
                v-model="recap.ad_group"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                outlined
                dense
                background-color="#fff"
                label="Comment"
                v-model="recap.comment"
                autocomplete="off"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-divider />
      <v-toolbar flat color="#fafafa">
        <v-spacer />
        <v-btn
          v-if="isExistingRecap"
          height="40"
          class="white--text mx-2"
          color="red"
          @click="confirmDelete = true"
        >
          Delete
          <v-icon right>mdi-trash-can-outline</v-icon>
        </v-btn>
        <v-btn
          height="40"
          class="white--text"
          color="green"
          @click="saveRecapAddition"
        >
          {{ isExistingRecap ? 'Update' : 'Save' }}
          <v-icon right>{{
            isExistingRecap
              ? 'mdi-update'
              : 'mdi-checkbox-marked-circle-outline'
          }}</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>
    <v-dialog
      justify="center"
      :value="confirmDelete"
      persistent
      max-width="500"
    >
      <v-card>
        <v-alert dense color="primary" class="rounded-0 white--text">
          <v-card-title class="text-capitalize">
            <v-icon left size="50" color="white">mdi-help-rhombus</v-icon>
            Delete Recap Addition?
            <v-spacer />
          </v-card-title>
        </v-alert>
        <v-card-text class="px-12">
          <v-col>
            Are you sure you want to delete this recap addition?
          </v-col>
          <v-col> Please click <b>Confirm</b> to continue. </v-col>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="confirmDelete = false">Cancel</v-btn>
          <v-btn class="grey lighten-2 ml-2" @click="deleteRecapAddition">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { notification } from '@/mixins/notification'

export default {
  name: 'RecapAdditionModal',
  props: [
    'value',
    'selectedRecap',
    'scopes',
    'components',
    'periods',
    'years',
    'stores',
    'adGroups',
    'getRecapAdditions',
  ],
  mixins: [notification],
  data() {
    return {
      confirmDelete: false,
      recap: {
        ad_group: {},
        amount: '',
        comment: '',
        component: {},
        period: '',
        scope: '',
        store: {},
        year: '',
      },
    }
  },
  computed: {
    isExistingRecap() {
      return 'id' in this.selectedRecap
    },
    formattedRecapAmount() {
      return this.recap?.amount ? parseFloat(this.recap.amount).toFixed(2) : ''
    },
    // text_amount: {
    //   get: function() {
    //     return parseFloat(this.recap.amount)
    //       .toFixed(2)
    //       .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    //   },
    //   set: function(value) {
    //     this.recap.amount = ((value.replace(/,/g, '') * 100) / 100).toFixed(2)
    //   },
    // },
    invalidRecap() {
      const recapScope = !this.recap.scope || this._.isEmpty(this.recap.scope)
      const recapAmount =
        !this.recap.amount && this._.isEmpty(this.recap.amount)
      const recapValidAmount =
        !parseFloat(this.recap.amount) < 0 || !parseFloat(this.recap.amount) > 0

      const recapParty =
        this.recap.scope === 'Ad Group'
          ? !this.recap.ad_group || this._.isEmpty(this.recap.ad_group)
          : !this.recap.store || this._.isEmpty(this.recap.store)

      const requiredFields = [
        recapScope,
        recapAmount,
        recapValidAmount,
        recapParty,
      ]

      return requiredFields.some((field) => field === true)
    },
    adGroupRules() {
      const rules = []

      if (this.recap.scope === 'Ad Group') {
        const rule = (v) => !!v || 'Ad Group is Required'
        rules.push(rule)
      }
      return rules
    },
    storeRules() {
      const rules = []

      if (this.recap.scope === 'Store') {
        const rule = (v) => !!v || 'Store is Required'
        rules.push(rule)
      }
      return rules
    },
    recapAmountRules() {
      const rules = []

      const rule1 = (v) => !!v || 'Recap Amount is Required'
      rules.push(rule1)

      const rule2 = (v) => parseFloat(v) !== 0 || 'Requires Non Zero Value'
      rules.push(rule2)

      return rules
    },
  },
  watch: {
    value: {
      handler() {
        if (this.value) {
          this.recap = {}
        }
      },
    },
    selectedRecap: {
      immediate: true,
      handler() {
        if (this.selectedRecap) {
          this.recap = this._.cloneDeep(this.selectedRecap)
          if (this.recap?.amount) {
            this.recap.amount = parseFloat(this.recap.amount).toFixed(2)
          }
        }
      },
    },
  },
  methods: {
    formatAmount(value) {
      this.recap.amount = parseFloat(value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    async deleteRecapAddition() {
      await this.$recaps
        .deleteRecapAddition(this.recap)
        .then(() => {
          this.$emit('update:value', false)
          this.$emit('update:value', false)
          this.notify('success', 'Recap Successfully Deleted')
        })
        .catch(() => {
          this.notify('error', 'Failed to Delete Recap')
        })
    },
    async saveRecapAddition() {
      const valid = this.$refs.form.validate()
      if (valid) {
        if (this.recap.store) {
          this.recap.store = {
            id: this.recap.store.id,
            name: this.recap.store.name,
          }
        }
        if (this.isExistingRecap) {
          await this.$recaps
            .updateRecapAddition(this.recap)
            .then(() => {
              this.$emit('update:value', false)
              this.notify('success', 'Recap Addition Updated Successfully')
            })
            .catch(() => {
              this.notify('error', 'Failed to Update Recap')
            })
        } else {
          await this.$recaps
            .saveRecapAddition(this.recap)
            .then(() => {
              this.notify('success', 'Recap Addition Saved Successfully')
              this.recap.amount = ''
              this.recap.scope == 'Store'
                ? (this.recap.store = {})
                : (this.recap.ad_group = {})
            })
            .catch(() => {
              this.notify('error', 'Failed to Create Recap')
            })
          this.$refs.form.resetValidation()
        }
        this.getRecapAdditions()
      } else {
        this.notify('error', 'Missing Required Fields')
      }
    },
  },
}
</script>
